import { Cell } from './cell';

export class Grid extends EventTarget {

    width = 8;
    height = 8;
    animationType = "flip";

    constructor(parentElement, menuContainer, infoContainer) {
        super();
        this.parentElement = parentElement;
        this.menuContainer = menuContainer;
        this.infoContainer = infoContainer;
        this.domElement = this.#createGrid();
        this.#addInputListeners();
        this.setSize(this.width, this.height);
    }

    #createGrid() {
        const grid = document.createElement("grid");
        grid.classList.add("squapp-grid");

        this.resizeObserver = new ResizeObserver(() => this.#onResize());
        this.resizeObserver.observe(this.parentElement);

        return grid;
    }

    #addInputListeners() {
        let touchingCells = [];
        let mousePressing = false;
        let touchDetected = false;

        const getCell = ({ pageX, pageY }) => {
            const gridBorder = 4;
            const rect = this.domElement.getBoundingClientRect();
            const left = rect.left + gridBorder;
            const top = rect.top + gridBorder;
            const width = rect.width - gridBorder * 2;

            const touchX = pageX - left;
            const touchY = pageY - top;
            const cellSize = (width - this.gap * (this.width + 1)) / this.width + this.gap;
            const x = Math.floor((touchX - this.gap / 2) / cellSize);
            const y = Math.floor((touchY - this.gap / 2) / cellSize);

            if (x < 0 || this.width <= x || y < 0 || this.height <= y) {
                return null;
            }

            return this.#getCell(x, y);
        };

        const handlePress = (o) => {
            const cell = getCell(o);
            
            if (!cell) {
                return;
            }
            
            touchingCells.push(cell); // to prevent entered
            cell.beingTouched = true;
            cell.clickTime = Date.now();
            
            this.dispatchEvent(new CustomEvent("pressed", {
                detail: { x: cell.x, y: cell.y },
            }));
        };

        const handleRelease = (o) => {
            const cell = getCell(o);

            if (!cell) {
                return;
            }

            const index = touchingCells.indexOf(cell);
            if (index >= 0) {
                touchingCells.splice(index, 1);
            }

            cell.beingTouched = false;

            this.dispatchEvent(new CustomEvent("released", {
                detail: { x: cell.x, y: cell.y },
            }));

            if (cell.clickTime !== 0 && Date.now() - cell.clickTime < 1000) {
                cell.clickTime = 0;

                this.dispatchEvent(new CustomEvent("tapped", {
                    detail: { x: cell.x, y: cell.y },
                }));
            }
        };

        const handleEntered = (o) => {
            const cell = getCell(o);

            if (!cell || touchingCells.includes(cell)) {
                return;
            }

            cell.beingTouched = true;
            cell.clickTime = 0;

            this.dispatchEvent(new CustomEvent("entered", {
                detail: { x: cell.x, y: cell.y },
            }));
        };

        const handleLeft = (currentTouchingCells) => {
            const noLongerTouchingCells = touchingCells.filter((x) => !currentTouchingCells.includes(x));

            for (const cell of noLongerTouchingCells) {
                cell.beingTouched = false;
                cell.clickTime = 0;

                this.dispatchEvent(new CustomEvent("left", {
                    detail: { x: cell.x, y: cell.y },
                }));
            }

            touchingCells = currentTouchingCells;
        };

        this.domElement.addEventListener("touchstart", (event) => {
            event.preventDefault();
            touchDetected = true;

            for (const touch of event.changedTouches) {
                handlePress(touch);
            }
        });

        this.domElement.addEventListener("touchend", (event) => {
            for (const touch of event.changedTouches) {
                handleRelease(touch);
            }
        });

        this.domElement.addEventListener("touchmove", (event) => {
            for (const touch of event.changedTouches) {
                handleEntered(touch);
            }

            const currentTouchingCells = Array.from(event.touches).map((x) => getCell(x)).filter((x) => x);
            handleLeft(currentTouchingCells);
        });

        this.domElement.addEventListener("mousedown", (event) => {
            if (touchDetected) {
                return;
            }

            if (event.button === 0) {
                mousePressing = true;
            }

            handlePress(event);
        });

        this.domElement.addEventListener("mouseup", (event) => {
            if (!mousePressing || touchDetected) {
                return;
            }

            if (event.button === 0) {
                mousePressing = false;
            }

            handleRelease(event);
        });

        this.domElement.addEventListener("mousemove", (event) => {
            if (!mousePressing || touchDetected) {
                return;
            }

            handleEntered(event);

            const currentTouchingCells = [getCell(event)].filter((x) => x);
            handleLeft(currentTouchingCells);
        });

        this.domElement.addEventListener("mouseleave", (event) => {
            if (!mousePressing) {
                return;
            }

            const currentTouchingCells = [getCell(event)].filter((x) => x);
            handleLeft(currentTouchingCells);
        });

        window.addEventListener("mouseup", () => {
            mousePressing = false;
        });
    }

    #onResize() {
        const menuRect = this.menuContainer.getBoundingClientRect();
        const infoRect = this.infoContainer.getBoundingClientRect();
        const flexGap = 24;

        let { width, height } = this.parentElement.getBoundingClientRect();

        height -= menuRect.height + infoRect.height + flexGap * 2;

        const cellSizeX = ((width - this.gap * (this.width + 1)) / this.width);
        const cellSizeY = ((height - this.gap * (this.height + 1)) / this.height);
        const widthY = cellSizeY * this.width + this.gap * (this.width + 1);
        const cellSize = Math.floor(widthY <= width ? cellSizeY : cellSizeX) - 0;

        this.domElement.style.gridTemplateColumns = `repeat(${this.width}, ${cellSize}px)`;
        this.domElement.style.gridTemplateRows = `repeat(${this.height}, ${cellSize}px)`;
        this.domElement.style.gap = `${this.gap}px`;
        this.domElement.style.padding = `${this.gap}px`;

        this.cells.forEach((cell) => {
            const fontSize = cellSize <= 36 ? Math.floor(cellSize / 2.1) : Math.floor(cellSize / 2.6);
            cell.symbolDomElement.style.fontSize = `${fontSize}px`;
        });

        const gridRect = this.domElement.getBoundingClientRect();
        this.menuContainer.style.width = `${gridRect.width}px`;
        this.infoContainer.style.width = `${gridRect.width}px`;
    }

    setSize(width, height) {
        this.width = width;
        this.height = height;
        this.gap = Math.max(this.width, this.height) > 10 ? 2 : 3;

        Array.from(this.domElement.childNodes).forEach((child) => {
            if (!child.classList.contains("squapp-grid-overlay"))
                this.domElement.removeChild(child);
        });
        this.cells = [];
        
        // TODO: keep existing cells, only add new cells when resizing larger
        for (let x = 0; x < this.width; x++) {
            for (let y = 0; y < this.height; y++) {
                const cell = new Cell(this.domElement, x, y);
                this.cells.push(cell);
            }
        }

        setTimeout(() => this.#onResize(), 0);
    }

    #getCell(x, y) {
        if (x < 0 || this.width <= x || y < 0 || this.height <= y)
            return null;
        const index = x * this.height + y;
        return this.cells[index];
    }

    setAllCellColors(color) {
        this.cells.forEach((cell) => {
            cell.setColor(color);
        });
    }

    setCellColor(x, y, color) {
        const cell = this.#getCell(x, y);
        if (!cell) return;
        cell.setColor(color);
    }

    getCellColor(x, y) {
        const cell = this.#getCell(x, y);
        if (!cell) return Cell.white;
        return cell.color;
    }

    setAllCellSymbols(symbol) {
        this.cells.forEach((cell) => {
            cell.setSymbol(`${symbol}`, cell.rotation);
        });
    }

    setCellSymbol(x, y, symbol) {
        const cell = this.#getCell(x, y);
        if (!cell) return;
        cell.setSymbol(`${symbol}`, cell.rotation);
    }

    getCellSymbol(x, y) {
        const cell = this.#getCell(x, y);
        if (!cell) return "";
        return cell.symbol;
    }

    setAllCellRotations(rotation) {
        this.cells.forEach((cell) => {
            cell.setSymbol(cell.symbol, rotation);
        });
    }

    setCellRotation(x, y, rotation) {
        const cell = this.#getCell(x, y);
        if (!cell) return;
        cell.setSymbol(cell.symbol, rotation);
    }

    getCellRotation(x, y) {
        const cell = this.#getCell(x, y);
        if (!cell) return 0;
        return cell.rotation;
    }

    animateAllCells(animationType) {
        this.cells.forEach((cell) => {
            cell.animate(animationType);
        });
    }

    animateCell(x, y, animationType) {
        const cell = this.#getCell(x, y);
        if (!cell) return;
        cell.animate(animationType);
    }

    isCellBeingTouched(x, y) {
        const cell = this.#getCell(x, y);
        if (!cell) return false;
        return cell.beingTouched;
    }
}
