import { Grid } from "./grid";
import { getWorkerCode } from "./worker";

import "./squapp.css";

/*
TODO:
- add argument validations (especially in worker)
- add share function (accepting text to share, can be used with colored boxes for grid snapshot)
*/

class Squapp extends EventTarget {
    constructor(parentElement) {
        super();
        this.parentElement = parentElement;
        this.domTree = null;
        this.grid = null;
        this.worker = null;
        // this.synth = new Tone.PolySynth(Tone.Synth).toDestination();
        // this.synth.set({ oscillator: { type: 'triangle' } });
        this.functions = this.#getFunctions();
    }

    #createDomTree() {
        const create = (className) => {
            const element = document.createElement("div");
            element.classList.add(className);
            return element;
        };

        //const root = document.createElement("div");

        // const overlay = create("squapp-overlay");
        const container = create("squapp-container");
        const menuContainer = create("squapp-menu-container");
        const gridContainer = create("squapp-grid-container");
        const infoContainer = create("squapp-info-container");
        infoContainer.style.display = "none";
        const gridOverlay = create("squapp-grid-overlay");
        const gridModal = create("squapp-grid-modal");
        //const modal = create("squapp-modal");
        //const modalContent = create("squapp-modal-content");
        //const modalFooter = create("squapp-modal-footer");
        //const modalButton = create("squapp-modal-button");

        // modalButton.textContent = "OK";
        
        //modalFooter.appendChild(modalButton);
        //modal.appendChild(modalContent);
        //modal.appendChild(modalFooter);
        container.appendChild(gridContainer);
        //container.appendChild(modal);
        //root.appendChild(overlay);
        //root.appendChild(container);

        gridOverlay.appendChild(gridModal);

        return {
            root: container,
            menuContainer,
            gridContainer,
            infoContainer,
            // modalContent,
            gridOverlay,
        };
    }

    #getFunctions() {
        const self = this;

        return {
            setSize(x, y) {
                self.grid.setSize(x, y);
            },

            setColor(color, x, y) {
                if (x === undefined || y === undefined) {
                    self.grid.setAllCellColors(color);
                } else {
                    self.grid.setCellColor(x, y, color);
                }
            },

            /*getColor(x, y) {
                return self.grid.getCellColor(x, y);
            },*/

            setSymbol(symbol, x, y) {
                if (x === undefined || y === undefined) {
                    self.grid.setAllCellSymbols(symbol);
                } else {
                    self.grid.setCellSymbol(x, y, symbol);
                }
            },

            /*setRotation(rotation, x, y) {
                if (x === undefined || y === undefined) {
                    self.grid.setAllCellRotations(rotation);
                } else {
                    self.grid.setCellRotation(x, y, rotation);
                }
            },*/

            /*getSymbol(x, y) {
                return self.grid.getCellSymbol(x, y);
            },*/

            /*getSymbolRotation(x, y) {
                return self.grid.getSymbolRotation(x, y);
            },*/

            animate(animationType, x, y) {
                if (x === undefined || y === undefined) {
                    self.grid.animateAllCells(animationType);
                } else {
                    self.grid.animateCell(x, y, animationType);
                }
            },

            /*isTouching(x, y) {
                self.grid.isTouchingCell(x, y);
            },*/

            /*setInfo(text) {
                // TODO
                self.domTree.infoContainer.textContent = text;
            },*/

            /*getInfo() {
                // TODO
            },*/

            showPopup(text) {
                self.domTree.gridOverlay.style.display = "flex";
                self.domTree.gridOverlay.childNodes[0].innerHTML = text;
            },

            /*playNote(note, octave = 2, delay = 0) {
                // self.synth.triggerAttackRelease(note + octave, "16n", Tone.now() + (delay / 3));
            },*/

            addMenu(index, label) {
                const menuItem = document.createElement("div");
                menuItem.classList.add("squapp-menu-item");
                const textItem = document.createTextNode(label);

                menuItem.appendChild(textItem);
                self.domTree.menuContainer.appendChild(menuItem);

                menuItem.addEventListener("click", () => {
                    self.domTree.gridOverlay.style.display = "none";
                    self.worker.postMessage({ eventName: "menu", index });
                });
            },

            clearMenu() {
                self.domTree.menuContainer.innerHTML = ""; // TODO remove eventListeners from children?
            }
        };
    }

    run(code) {
        if (this.domTree) {
            this.parentElement.removeChild(this.domTree.root);
        }

        this.domTree = this.#createDomTree();

        const { gridContainer, menuContainer, infoContainer } = this.domTree;
        this.grid = new Grid(gridContainer, menuContainer, infoContainer); // TODO: dispose grid: unobserve parent internally + removeEventListeners below
        gridContainer.appendChild(menuContainer);
        gridContainer.appendChild(this.grid.domElement);
        gridContainer.appendChild(infoContainer);
        this.grid.domElement.appendChild(this.domTree.gridOverlay);

        if (this.worker) {
            this.worker.terminate();
            this.worker = null;
        }

        const workerCode = getWorkerCode(JSON.stringify(code));
        const blob = new Blob([workerCode], { type: "text/javascript" });

        ["tapped", "pressed", "entered", "released", "left"].forEach((eventName) => {
            this.grid.addEventListener(eventName, (event) => {
                if (this.domTree.gridOverlay.style.display === "flex") {
                    if (eventName === "pressed") {
                        this.domTree.gridOverlay.style.display = "none";
                    }
                    return;
                }
                const { detail: { x, y } } = event;
                this.worker.postMessage({ eventName, x, y });
            });
        });

        this.worker = new Worker(URL.createObjectURL(blob));
        this.worker.onmessage = (event) => {
            const { name, args } = event.data;

            if (name === "run") {
                this.parentElement.appendChild(this.domTree.root)
                return;
            }

            if (name === "error") {
                const [type, message, line] = args;
                console.error(`Squapp ${type}${line > 0 ? ` at line ${line}` : ""}: ${message}`);

                this.dispatchEvent(new CustomEvent("error", {
                    detail: args,
                }));

                return;
            }

            if (this.functions.hasOwnProperty(name)) {
                this.functions[name](...args);
            }
        };
        this.worker.onerror = (event) => { };
        
        //setTimeout(() => this.parentElement.appendChild(this.domTree.root), 300); // TODO: fix without (significant) delay
    }
}

window.Squapp = Squapp;
