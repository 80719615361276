export class Cell {

    static colors = {
        white: "rgb(250, 250, 250)",
        black: "rgb(180, 180, 180)",
        red: "hsl(10, 80%, 75%)",
        green: "hsl(120, 80%, 72%)",
        blue: "hsl(210, 90%, 85%)",
        yellow: "hsl(50, 80%, 70%)",
        orange: "hsl(30, 90%, 75%)",
        purple: "hsl(240, 80%, 85%)",
        brown: "hsl(40, 50%, 70%)",
        transparent: "rgba(0, 0, 0, 0)",
    };

    static animations = {
        flip: "flip 0.2s ease",
        spin: "spin 0.2s ease",
        pop: "pop 0.15s ease",
        blink: "blink 0.25s linear",
        fade: "fade 0.4s ease",
    };

    beingTouched = false;
    clickTime = 0;

    constructor(parentElement, x, y) {
        this.parentElement = parentElement;
        this.x = x;
        this.y = y;
        this.domElement = this.#createCell();
        this.parentElement.appendChild(this.domElement);
        this.setColor("white");
        this.setSymbol("", 0);
    }

    #createCell() {
        const cell = document.createElement("div");
        cell.classList.add("squapp-grid-cell");

        cell.style.gridColumn = `${this.x + 1} / ${this.x + 2}`;
        cell.style.gridRow = `${this.y + 1} / ${this.y + 2}`;
        cell.style.backgroundColor = "transparent";

        const background = document.createElement("div");
        background.classList.add("squapp-grid-cell-background");
        cell.appendChild(background);
        this.backgroundDomElement = background;

        const symbol = document.createElement("div");
        symbol.classList.add("squapp-grid-cell-symbol");
        cell.appendChild(symbol);
        this.symbolDomElement = symbol;

        return cell;
    }

    setColor(color) {
        if (this.color === color) {
            return;
        }

        this.backgroundDomElement.style.backgroundColor = Cell.colors[color];

        switch (color) {
            case "white":
                this.backgroundDomElement.classList.remove("squapp-grid-cell-transparent");
                this.backgroundDomElement.classList.remove("squapp-grid-cell-colored");
                this.backgroundDomElement.classList.add("squapp-grid-cell-white");
                break;

            case "transparent":
                this.backgroundDomElement.classList.remove("squapp-grid-cell-white");
                this.backgroundDomElement.classList.remove("squapp-grid-cell-colored");
                this.backgroundDomElement.classList.add("squapp-grid-cell-transparent");
                break;

            default:
                if (this.color === "white" || this.color === "transparent") {
                    this.backgroundDomElement.classList.remove("squapp-grid-cell-white");
                    this.backgroundDomElement.classList.remove("squapp-grid-cell-transparent");
                    this.backgroundDomElement.classList.add("squapp-grid-cell-colored");
                }
                break;
        }

        this.color = color;
    }

    setSymbol(symbol, rotation) {
        this.symbol = symbol.length === 0 ? "" : Array.from(symbol)[0];
        this.rotation = -Math.round(rotation) % 360;

        this.symbolDomElement.textContent = symbol;
        this.symbolDomElement.style.transform = rotation === 0 ? "" : `rotateZ(${rotation}deg)`;
    }

    animate(animationType) {
        const animation = Cell.animations[animationType];
        
        if (animation) {
            const element = ["spin", "fade"].includes(animationType) ? this.backgroundDomElement : this.domElement;

            element.style.animationName = "none";

            void element.offsetWidth; // necessary to trigger reflow (makes animation reset immediately)!
            
            element.style.animation = animation;
        }
    }
}
