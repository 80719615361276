export const getWorkerCode = (code) => {  return `

    // TODO: limit access to certain functions via whitelist
    // I.e. check https://stackoverflow.com/questions/10653809/making-webworkers-a-safe-environment/

    ;(() => {
        let __parseError;
        let __notifyFirstRunDone;

        ;(() => {
            const postMessage = self.postMessage;
            const menuItems = [];

            __parseError = (err, reason) => {
                function indexOf(s, token) {
                    const index = s.indexOf(token);
                    if (index < 0) return -1;
                    return index + token.length;
                }

                function parseErrorLine(err) {
                    if (err.stack) {
                        const lines = err.stack.split('\\n');
                        for (const line of lines) {
                            let index = indexOf(line, '<anonymous>:');
                            if (index < 0) index = indexOf(line, ' > eval:');
                            if (index < 0) continue;
                            let lineno = line.slice(index, line.indexOf(':', index));
                            return lineno;
                        }
                    }
                    return err.lineNumber ?? -1;
                }

                let name, message, line;

                if (reason) {
                    name = "Error",
                    message = err.message;
                    line = parseErrorLine(err);
                } else {
                    name = err.name;
                    message = err.message;
                    line = parseErrorLine(err);
                }

                postMessage({ name: 'error', args: [name, message, line] });
            };

            __notifyFirstRunDone = () => {
                postMessage({ name: 'run', args: [] });
            };

            self.onerror = (err) => { };

            self.onunhandledrejection = (err) => { __parseError(err.reason, true) };

            self.onmessage = (event) => {
                const { eventName, x, y, index } = event.data;
                
                try {
                    if (eventName === "menu") {
                        menuItems[index].callback();
                        return;
                    }

                    const cell = cells[x + y * maxWidth];

                    switch (eventName) {
                        case "tapped":
                            self.tapped(x, y);
                            break;

                        case "pressed":
                            cell.beingTouched = true;
                            self.pressed(x, y);
                            self.touched(x, y);
                            break;

                        case "released":
                            cell.beingTouched = false;
                            self.released(x, y);
                            self.untouched(x, y);
                            break;

                        case "entered":
                            cell.beingTouched = true;
                            self.entered(x, y);
                            self.touched(x, y);
                            break;

                        case "left":
                            cell.beingTouched = false;
                            self.left(x, y);
                            self.untouched(x, y);
                            break;
                    }
                } catch (err) {
                    __parseError(err);
                }
            };

            ;((setInterval) => {
                self.setInterval = (fn, ...rest) => {
                    return setInterval((...args) => {
                        try {
                            fn(...args);
                        } catch (err) {
                            __parseError(err);
                        }
                    }, ...rest);
                };
            })(setInterval);

            ;((setTimeout) => {
                self.setTimeout = (fn, ...rest) => {
                    return setTimeout((...args) => {
                        try {
                            fn(...args);
                        } catch (err) {
                            __parseError(err);
                        }
                    }, ...rest);
                };
            })(setTimeout);

            const whitelist = {
                "undefined": true,
                "NaN": true,
                "Infinity": true,
    
                "eval": true,
                "isNaN": true,
                "isFinite": true,
                "parseInt": true,
                "parseFloat": true,
                "decodeURI,": true,
                "decodeURIComponent": true,
                "encodeURI": true,
                "encodeURIComponent": true,
                "setTimeout": true,
                "setInterval": true,
                "clearTimeout": true,
                "clearInterval": true,
                "btoa": true,
                "atob": true,
    
                "Function": true,
                "Object": true,
                "Array": true,
                "Boolean": true,
                "JSON": true,
                "Date": true,
                "Math": true,
                "Number": true,
                "String": true,
                "Error": true,
                "RegExp": true,
                "ArrayBuffer": true,
                "Int8Array": true,
                "Uint8Array": true,
                "Int16Array": true,
                "Uint16Array": true,
                "Int32Array": true,
                "Uint32Array": true,
                "Float32Array": true,
                "Float64Array": true,
                "Uint8ClampedArray": true,
                "BigInt64Array": true,
                "BigUint64Array": true,
                "BigInt": true,
                "WeakMap": true,
                "Map": true,
                "Set": true,
                "DataView": true,
                "Symbol": true,
                "Intl": true,
                "Proxy": true,
                "Reflect": true,
                "WeakSet": true,
                "Promise": true,
                "WeakRef": true,
                "TextDecoder": true,
                "TextEncoder": true,
                "URL": true,
                "URLSearchParams": true,

                "console": true,
            };

            const accessError = (prop) => {
                return new Error("You cannot use '" + prop + "' in a squapp.");
            };

            Object.getOwnPropertyNames(WorkerGlobalScope.prototype).forEach((prop) => {
                if (prop !== "self" && !whitelist.hasOwnProperty(prop)) {
                    Object.defineProperty(WorkerGlobalScope.prototype, prop, {
                        get() {
                            throw accessError(prop);
                        },
                        set() {
                            throw accessError(prop);
                        },
                        configurable: false,
                    });
                }
            });
            
            Object.getOwnPropertyNames(self).forEach((prop) => {
                if (!whitelist.hasOwnProperty(prop)) {
                    Object.defineProperty(self, prop, {
                        get() {
                            throw accessError(prop);
                        },
                        set() {
                            throw accessError(prop);
                        },
                        configurable: false,
                    });
                }
            });

            let invocations = [];

            const tick = () => {
                if (invocations.length > 0) {
                    invocations.forEach(x => postMessage(x));
                    invocations.length = 0;
                }
            };
            //setInterval(tick, 10);

            const invoke = (name, args) => {
                // TODO: possibilities for optimization if necessary
                // E.g. bundle multiple calls within single "tick"
                // Use transferable objects, if possible

                postMessage({ name, args });
                //invocations.push({ name, args });
            };
            
            const maxWidth = 20;
            const maxHeight = 20;
            const cells = [...Array(maxWidth * maxHeight)].map((_, index) => {
                return {
                    x: index % maxWidth,
                    y: Math.floor(index / maxWidth),
                    color: 'white',
                    symbol: '',
                    rotation: 0,
                    beingTouched: false,
                }
            });
            const tiles = [...Array(maxWidth * maxHeight)].map((_, index) => {
                return {
                    get x() { return index % maxWidth; },
                    get y() { return Math.floor(index / maxWidth); },
                    toString() { return "[" + this.x + ", " + this.y + "]"; },
                }
            });
            let width = 8;
            let height = 8;
            let info = "";
            let currentTiles = tiles;
    
            const functions = {
                setSize(w, h) {
                    if (typeof w !== "number") w = 8;
                    if (typeof h !== "number") h = 8;
                    w = Math.max(1, Math.min(w, maxWidth));
                    h = Math.max(1, Math.min(h, maxHeight));

                    width = w;
                    height = h;
                    currentTiles = [];
                    for (let y = 0; y < height; y++) {
                        for (let x = 0; x < width; x++) {
                            currentTiles.push(tiles[x + y * maxWidth]);
                        }
                    }
                    
                    return [w, h];
                },
    
                setColor(color, x, y) {
                    if (x === undefined || y === undefined) {
                        for (let i = 0; i < width * height; i++) {
                            cells[(i % width) + Math.floor(i / width) * maxWidth].color = color;
                        }
                    } else {
                        cells[x + y * maxWidth].color = color;
                    }
                    return [color, x, y];
                },
    
                setSymbol(symbol, x, y) {
                    if (x === undefined || y === undefined) {
                        for (let i = 0; i < width * height; i++) {
                            cells[(i % width) + Math.floor(i / width) * maxWidth].symbol = symbol;
                        }
                    } else {
                        cells[x + y * maxWidth].symbol = symbol;
                    }
                    return [symbol, x, y];
                },

                /*setRotation(rotation, x, y) {
                    if (x === undefined || y === undefined) {
                        for (let i = 0; i < width * height; i++) {
                            cells[(i % width) + Math.floor(i / width) * maxWidth].rotation = rotation;
                        }
                    } else {
                        cells[x + y * maxWidth].rotation = rotation;
                    }
                    return [rotation, x, y];
                },*/
    
                animate(animationType, x, y) {
                    return [animationType, x, y];
                },
    
                /*playNote(note, octave, delay) {
                    return [note, octave, delay];
                },*/
    
                /*setInfo(text) {
                    info = text;
                    return [text];
                },*/
    
                addMenu(label, callback) {
                    menuItems.push({ label, callback });
                    return [menuItems.length - 1, label];
                },

                clearMenu() {
                    return [];
                },
    
                showPopup(text) {
                    return [text];
                },
            }
    
            for (const [name, validate] of Object.entries(functions)) {
                self[name] = (...args) => invoke(name, validate(...args));
            }
    
            self.getColor = (x, y) => {
                return cells[x + y * maxWidth].color;
            };
    
            self.getSymbol = (x, y) => {
                return cells[x + y * maxWidth].symbol;
            };

            /*self.getRotation = (x, y) => {
                return cells[x + y * maxWidth].rotation;
            };*/

            /*self.isTouching = (x, y) => {
                return cells[x + y * maxWidth].beingTouched;
            };*/

            /*self.getInfo = () => {
                return info;
            };*/

            self.transparent = "transparent";
            self.white = "white";
            self.black = "black";
            self.red = "red";
            self.green = "green";
            self.blue = "blue";
            self.yellow = "yellow";
            self.orange = "orange";
            self.purple = "purple";
            self.brown = "brown";

            self.flip = "flip";
            self.spin = "spin";
            self.pop = "pop";
            self.blink = "blink";
            self.fade = "fade";

            Object.defineProperty(self, "width", {
                get() {
                    return width;
                }
            });

            Object.defineProperty(self, "height", {
                get() {
                    return height;
                }
            });

            Object.defineProperty(self, "tiles", {
                get() {
                    return currentTiles;
                }
            });
        })();

        function tapped(x, y) { }
        function pressed(x, y) { }
        function released(x, y) { }
        function entered(x, y) { }
        function left(x, y) { }
        function touched(x, y) { }
        function untouched(x, y) { }

        setSize(8, 8);

        try {
            eval(${code});
        } catch (err) {
            __parseError(err);
        } finally {
            self.tapped = tapped;
            self.pressed = pressed;
            self.released = released;
            self.entered = entered;
            self.left = left;
            self.touched = touched;
            self.untouched = untouched;
        }

        __notifyFirstRunDone();
    })();

/*//*/`
};
